<template>
  <div class="modal text-gray-800 border-t-4 border-blue-500">
    <div class="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
      <h3 class="text-lg leading-6 font-medium text-gray-900">
        Scene Setup
      </h3>
    </div>

    <div class="modal-content px-8 py-4 bg-neutral-gray">
      <template v-if="error">
        <div>
          <p class="font-bold text-red-500">
            There was a problem exporting & processing this scene
          </p>
          <p>The specific error encountered was: </p>
          <p>{{ errorMessage }}</p>
        </div>
      </template>
      <template v-else-if="complete">
        <div>
          <p class="font-bold text-green-500">
            <fa-icon
              icon="check"
              class="fa-fw mr-1"
            />
            Ready
          </p>

          <div class="mt-1 text-sm">
            <ul class="list-disc ml-6">
              <li>Scene assets have been saved to the server.</li>
              <li>Texture processing and optimisation underway.</li>
              <li>The editor is ready to use.</li>
            </ul>
          </div>
        </div>
      </template>

      <template v-else>
        <template v-if="isImportMode">
          <div>
            <p>Are you sure you want to upload <span class="font-bold">{{ file.name }}</span>?</p>
          </div>
          <div class="mt-1 text-sm">
            <p>
              This will replace the current scene contents.<br>
              Upload and processing may take a short while, depending on scene complexity.
            </p>
          </div>

          <div
            v-if="isLoading"
            class="bg-white p-4 font-mono text-xs m-2 rounded"
          >
            {{ loadingVerb }} {{ loadingPc }}%
            <span class="inline-block w-full bg-white bg-opacity-50 h-1 relative rounded-sm">
              <span
                class="inline-block bg-blue-500 h-1 absolute"
                :style="loadingBarStyle"
              />
            </span>
          </div>
        </template>

        <!-- <div class="bg-white p-4 font-mono text-xs m-2 rounded" v-if="hasMessages">
            <div class="block" v-for="message in messages">
                <span class="block" v-for="msg in message.messages">{{ msg }}</span>
            </div>
          </div> -->

        <template v-if="!isImportMode">
          <div
            v-if="isLoading"
            class="bg-white p-4 font-mono text-xs m-2 rounded"
          >
            {{ loadingVerb }} {{ loadingPc }}%
            <span class="inline-block w-full bg-white bg-opacity-50 h-1 relative rounded-sm">
              <span
                class="inline-block bg-blue-500 h-1 absolute"
                :style="loadingBarStyle"
              />
            </span>
          </div>
          <div
            v-else
            class="mt-1 text-sm"
          >
            <p>Upload and processing may take a short while, depending on scene complexity.</p>
            <p>After processing your scene will be ready to use.</p>
          </div>
        </template>
      </template>

      <div
        v-if="showActions"
        class="mt-6 btn-group flex items-center justify-end"
      >
        <template v-if="error">
          <button
            class="btn"
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="btn positive"
            type="button"
            @click.prevent="confirm"
          >
            <fa-icon
              icon="upload"
              class="fa-fw mr-1"
            /> Retry
          </button>
        </template>
        <template v-else-if="complete">
          <button
            class="btn primary"
            type="button"
            @click="$emit('close')"
          >
            Close & Continue
          </button>
        </template>
        <button
          v-else-if="busy"
          class="btn positive"
          disabled
          type="button"
        >
          <fa-icon
            icon="spinner"
            spin
            class="fa-fw mr-1"
          /> {{ verb }}
        </button>
        <template v-else>
          <button
            class="btn"
            type="button"
            @click="$emit('close')"
          >
            Cancel
          </button>
          <button
            class="btn positive"
            type="button"
            @click.prevent="confirm"
          >
            <fa-icon
              icon="upload"
              class="fa-fw mr-1"
            /> Process Scene
          </button>
        </template>
      </div>
    </div>
  </div>

  </div>
</template>

<script>
export default {

  name: 'SceneBaseSetup',

  props: {
    file: {
      required: false,
      default: null
    },
    gui: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      busy: false,
      success: false,
      importing: false,
      complete: false,
      error: false,
      errorMessage: null
    }
  },

  computed: {

    showActions () {
      if (!this.isImportMode && this.isLoading) return false
      return true
    },

    isImportMode () {
      return this.file !== null
    },

    verb () {
      if (this.importing) return 'Importing & Unpacking'
      if (this.saving) return 'Uploading to Server'
      if (this.error) return 'Failed'
      return 'Working'
    },

    hasMessages () {
      return this.messages.length > 0
    },

    messages () {
      if (this.gui === null) return []
      return this.gui.recentMessages
    },

    loadingMessage () {
      if (this.gui === null || this.gui.isLoading === false) return null
      return {
        message: 'Loading',
        pc: this.gui.loadingPc
      }
    },

    isLoading () {
      return this.loadingPc > 0
    },

    loadingPc () {
      if (this.loadingMessage) {
        return this.loadingMessage.pc
      }
      return 0
    },

    loadingVerb () {
      if (this.loadingMessage) {
        return this.loadingMessage.message
      }

      return 'Loading'
    },

    loadingBarStyle () {
      return 'width: ' + this.loadingPc + '%'
    }
  },

  mounted () {
    this.attachListeners()
  },

  methods: {

    attachListeners () {
      this.$bus.$on('scene:model:save-request-completed', this.onSaveSuccess)
      this.$bus.$on('scene:model:save-request-failed', this.onSaveFailure)
    },

    dettachListeners () {
      this.$bus.$off('scene:model:save-request-completed')
      this.$bus.$off('scene:model:save-request-failed')
    },

    onSaveSuccess () {
      this.saving = false
      this.complete = true
    },

    onSaveFailure (error) {
      this.saving = false
      this.error = true
      this.errorMessage = error
    },

    confirm () {
      this.busy = true

      setTimeout(() => {
        if (this.isImportMode) {
          this.importing = true
          this.gui.importIntoScene(this.file, true, () => {
            this.initFullSave()
            this.importing = false
          })
        } else {
          this.initFullSave()
        }
      }, 100)
    },

    initFullSave () {
      this.saving = true
      this.$bus.$emit('scene:model:request-full-save')
    }

  }
}

</script>
